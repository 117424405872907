import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useLoaderData } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

import Header from "../components/Header/Header";
import SideNavigationBar from "../components/SideNavigationBar/SideNavigationBar";
import MetaTags from "../components/MetaTags/MetaTags";

const drawerWidth = 240;

const UserCard = ({ avatar, firstName, lastName, email }) => {
  const [isInternalUser, setIsInternalUser] = useState(false);

  useEffect(() => {
    if (
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("isInternalUser="))
    ) {
      setIsInternalUser(true);
    }
  }, []);

  return (
    <Card sx={{ maxWidth: 345, margin: "20px auto", padding: "16px" }}>
      <CardContent>
        {isInternalUser ? (
          <Avatar aria-label="profile picture" sx={{ background: "#18136C" }}>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
        ) : (
          <Avatar aria-label="profile picture" src={avatar} />
        )}
        <Typography variant="h5" component="div">
          {firstName} {lastName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {email}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Settings = () => {
  const auth = useAuth();
  const userData = useLoaderData();

  return (
    auth.isAuthenticated && (
      <>
        <MetaTags
          title="Fluent Pulse I AI-powered advertising & monetization platform"
          description="Settings page of Fluent Pulse"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              background: "#18136C",
            }}
          >
            <Toolbar>
              <Header data={userData} isSettingsPage={true} />
            </Toolbar>
          </AppBar>
          <SideNavigationBar data={userData} />
          <Box width="100%" height="100%" marginTop="130px">
            <UserCard
              avatar={userData.avatar}
              firstName={userData.firstName}
              lastName={userData.lastName}
              email={userData.email}
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default Settings;
