const ErrorComponent = ({ errorData }) => {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorData.message}</i>
      </p>
    </div>
  )
};

export default ErrorComponent;