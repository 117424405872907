import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import axios from "axios";
import * as Sentry from "@sentry/react";

import "./index.css";
import ErrorBoundary from "./components/errors/ErrorBoundary";

import Home from "./routes/Home";
import Settings from "./routes/Settings";
import InternalDomoReport from "./routes/InternalDomoReport";
import LoggerService from "./services/LoggerService";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  tunnel: `${process.env.REACT_APP_LAMBDA_PROXY_ENDPOINT}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "*.fluentco.com",
    "*.minionplatform.com",
  ],
});

function App() {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const auth = useAuth();

  async function getUserData() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth.user.access_token}`,
        Accept: "*/*",
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_HOSTNAME}/v1/account/profile`,
      config
    );

    const defaultClient =
      data.partners && data.partners && data.partners.length
        ? data.partners[0].name
        : "";
    return { ...data, defaultClient };
  }

  const router = sentryCreateBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
    {
      path: "settings",
      element: <Settings />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
    {
      path: "internal-domo-report",
      element: <InternalDomoReport />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
  ]);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      if (params.idpHint) {
        auth.signinRedirect({
          extraQueryParams: { kc_idp_hint: params.idpHint },
        });
        document.cookie = "isInternalUser=true; SameSite=None; Secure";
      } else {
        auth.signinRedirect();
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, hasAuthParams]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    LoggerService.sendEvent("app_login_error", "error", {
      error: auth.error,
      userProfile: auth.user.profile,
    });
    return <div>Oops... {auth.error.message}</div>;
  }

  return <RouterProvider router={router} />;
}

export default App;
