import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

import { Avatar, Button, IconButton, Popover, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const Profile = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInternalUser, setIsInternalUser] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  const handleLogout = async () => {
    if (isInternalUser) {
      setIsInternalUser(true);
      auth.signoutRedirect({
        post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}?idpHint=saml-okta`,
      });
    } else {
      auth.signoutRedirect({
        post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("isInternalUser="))
    ) {
      setIsInternalUser(true);
    }
  }, []);

  return (
    <div>
      <IconButton onClick={handleClick} aria-describedby={id}>
        {isInternalUser ? (
          <Avatar aria-label="profile picture" sx={{ background: "#18136C" }}>
            {data.firstName[0]}
            {data.lastName[0]}
          </Avatar>
        ) : (
          <Avatar aria-label="profile picture" src={data.avatar} />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom>
            {data.firstName} {data.lastName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {data.email}
          </Typography>
          <Button
            onClick={handleSettingsClick}
            startIcon={<SettingsIcon />}
            variant="outlined"
            fullWidth
            data-testid="settings-button"
          >
            Settings
          </Button>
          <Button
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            variant="outlined"
            fullWidth
          >
            Logout
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default Profile;
