import { useRouteError, isRouteErrorResponse } from "react-router-dom";

import {NO_PARTNERS, NO_PARTNERS_URLS} from "./ErrorConstants";
import ErrorComponent from "./ErrorComponent";

const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.data.message === NO_PARTNERS) {
      return (
        <ErrorComponent errorData={error} />
      )
    }

    if (error.data.message === NO_PARTNERS_URLS) {
      return (
        <ErrorComponent errorData={error} />
      )
    }
  }
};

export default ErrorBoundary;
