import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Box, CssBaseline, AppBar, Toolbar } from "@mui/material";

import Header from "../components/Header/Header";
import SideNavigationBar from "../components/SideNavigationBar/SideNavigationBar";
import { useLoaderData } from "react-router-dom";
import DomoReport from "../components/DomoReport/DomoReport";
import MetaTags from "../components/MetaTags/MetaTags";

const drawerWidth = 240;

const Home = () => {
  const [selectedClient, setSelectedClient] = useState("");

  const auth = useAuth();
  const userData = useLoaderData();

  useEffect(() => {
    setSelectedClient(userData.defaultClient);
  }, []);

  const handleClientChange = (newState) => {
    setSelectedClient(newState);
  };

  return (
    auth.isAuthenticated && (
      <>
        <MetaTags
          title="Fluent Pulse I AI-powered advertising & monetization platform"
          description="Leverage first-party data and advanced machine learning to deliver personalization and enhance brand connections with online shoppers."
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              background: "#18136C",
            }}
          >
            <Toolbar>
              <Header
                data={userData}
                client={selectedClient}
                onChange={handleClientChange}
              />
            </Toolbar>
          </AppBar>
          <SideNavigationBar data={userData} />
          <Box width="100%" height="100%" marginTop="130px">
            <DomoReport client={selectedClient} />
          </Box>
        </Box>
      </>
    )
  );
};

export default Home;
