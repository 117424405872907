import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import App from "./App";

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: `${process.env.REACT_APP_KEYCLOAK_AUTHORITY}`,
  client_id: `${process.env.REACT_APP_KEYCLOAK_AUDIENCE}`,
  redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
  automaticSilentRenew: true,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </AuthProvider>
  </React.StrictMode>
);
