import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

const DomoReport = ({ client }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(false);
    setTimeout(() => {
      setIframeLoaded(true);
    }, 3000);
  };

  return (
    <>
      {!iframeLoaded && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}{" "}
      <iframe
        src={
          client
            ? `/internal-domo-report?client=${encodeURIComponent(client)}`
            : ""
        }
        width="100%"
        height="100%"
        frameBorder="0"
        title="Domo iframe"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
        allowFullScreen
        onLoad={handleIframeLoad}
        style={{ display: iframeLoaded ? "block" : "none" }}
      />
    </>
  );
};

export default DomoReport;
