import * as Sentry from "@sentry/react";

export class LoggerService {
  static sendEvent = (message, level, data) => {
    const event = {
      message,
      level,
      extra: data,
    };

    Sentry.captureEvent(event);
  };
}

export default LoggerService;
