import { Drawer, List, MenuItem, Toolbar } from "@mui/material";
import Profile from "../Profile/Profile";
import logo from "../../assets/Fluent_Pulse_Logo-01.svg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const SideNavigationBar = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          background: "#292EB1",
          width: drawerWidth,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ color: "white" }}>
        <img src={logo} alt="logo" />
      </Toolbar>
      <List>
        <a
          href={`${process.env.REACT_APP_REDIRECT_URI}`}
          style={{ textDecoration: "none" }}
          onClick={() => navigate()}
        >
          <MenuItem
            disableRipple
            disableGutters
            sx={{
              color: "white",
              backgroundColor: "#18136C",
              paddingLeft: "20px",
              height: "60px",
              position: "relative",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "5px",
                height: "100%",
                background: "#1890FF",
              }}
            ></span>{" "}
            Home
          </MenuItem>
        </a>
      </List>
      <div style={{ marginTop: "auto" }}>
        <Profile data={data} />
      </div>
    </Drawer>
  );
};

export default SideNavigationBar;
